// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-data-driven-ui-mdx": () => import("./../../../src/pages/blog/data-driven-ui.mdx" /* webpackChunkName: "component---src-pages-blog-data-driven-ui-mdx" */),
  "component---src-pages-blog-handmake-databases-intro-mdx": () => import("./../../../src/pages/blog/handmake-databases-intro.mdx" /* webpackChunkName: "component---src-pages-blog-handmake-databases-intro-mdx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-rapid-iteration-backend-mdx": () => import("./../../../src/pages/blog/rapid-iteration-backend.mdx" /* webpackChunkName: "component---src-pages-blog-rapid-iteration-backend-mdx" */),
  "component---src-pages-blog-scenario-replays-mdx": () => import("./../../../src/pages/blog/scenario-replays.mdx" /* webpackChunkName: "component---src-pages-blog-scenario-replays-mdx" */),
  "component---src-pages-blog-scenario-replays-requirements-mdx": () => import("./../../../src/pages/blog/scenario-replays-requirements.mdx" /* webpackChunkName: "component---src-pages-blog-scenario-replays-requirements-mdx" */),
  "component---src-pages-blog-storex-lessons-learned-mdx": () => import("./../../../src/pages/blog/storex-lessons-learned.mdx" /* webpackChunkName: "component---src-pages-blog-storex-lessons-learned-mdx" */),
  "component---src-pages-blog-storex-modular-storage-mdx": () => import("./../../../src/pages/blog/storex-modular-storage.mdx" /* webpackChunkName: "component---src-pages-blog-storex-modular-storage-mdx" */),
  "component---src-pages-blog-team-conversations-tech-mdx": () => import("./../../../src/pages/blog/team-conversations-tech.mdx" /* webpackChunkName: "component---src-pages-blog-team-conversations-tech-mdx" */),
  "component---src-pages-coworking-mdx": () => import("./../../../src/pages/coworking.mdx" /* webpackChunkName: "component---src-pages-coworking-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-katachi-presentation-tsx": () => import("./../../../src/pages/katachi/presentation.tsx" /* webpackChunkName: "component---src-pages-katachi-presentation-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-thoughts-in-progress-index-tsx": () => import("./../../../src/pages/thoughts-in-progress/index.tsx" /* webpackChunkName: "component---src-pages-thoughts-in-progress-index-tsx" */),
  "component---src-pages-thoughts-in-progress-lean-web-stack-mdx": () => import("./../../../src/pages/thoughts-in-progress/lean-web-stack.mdx" /* webpackChunkName: "component---src-pages-thoughts-in-progress-lean-web-stack-mdx" */),
  "component---src-pages-thoughts-in-progress-webdev-workflows-mdx": () => import("./../../../src/pages/thoughts-in-progress/webdev-workflows.mdx" /* webpackChunkName: "component---src-pages-thoughts-in-progress-webdev-workflows-mdx" */),
  "component---src-pages-work-with-me-mdx": () => import("./../../../src/pages/work-with-me.mdx" /* webpackChunkName: "component---src-pages-work-with-me-mdx" */)
}

